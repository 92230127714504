import React from "react"
import NewLayout from '../layout/newLayout'
import SEO from "../components/seo"
import Title from "../components/title"
import Button from "../components/button"
import styles from "../styles/scss/templates/jobdoneright.module.scss"
import YouTube from "react-youtube"
import { supSub } from "../services/util"

const JobDoneRight = ({ ...props }) => {
    const pageData = props.pageContext.page
    const pageVideo = pageData.content[0]
    const pageText = pageData.content[1]

    const opts = {
        playerVars: {
            autoplay: 1,
            controls: 0,
            start: 1,
            allow: "autoplay",
            rel: 0,
        },
    }

    return (
        <NewLayout>
            <SEO page={pageData} indexableFollowed={false} />

            <div className={styles.jobdonerightWrapper}>
                <Title className={styles.jobdoneTitle}>{pageText.subtitle}</Title>

                {pageVideo.video && (
                    <section className={styles.videoWrapper}>
                        <div className={styles.iframeContainer}>
                            <YouTube
                                videoId={pageVideo.video.providerUid}
                                opts={opts}
                                autoPlay={true}
                            />
                        </div>
                    </section>
                )}

                {pageText && (
                    <section className={styles.textWrapper}>
                        {pageText.body && (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: supSub(pageText.body),
                                }}
                            />
                        )}
                    </section>
                )}
                <div className={styles.buttonWrapper}>
                    <Button
                        red
                        text="See the WD-40 Specialist Products"
                        url="/specialist"
                    />
                </div>
            </div>
        </NewLayout>
    )
}

export default JobDoneRight
